const apis = {
  getTreatmentImg: {
    method: 'GET',
    url: 'direcipeitem/getTreatmentImg',
    group: 'adminSsyypt',
    dataType: 'json'
  }
}

export default apis
