const apis = {
  getAddMeQRCodeFromUnitId: {
    method: 'GET',
    url: 'qywxuser/getAddMeQRCodeFromUnitId',
    group: 'ums',
    dataType: 'form'
  }
}

export default apis
