const apis = {
  createCourseVipOrder: {
    method: 'POST',
    url: 'order/createCourseVipOrder',
    group: 'oms',
    dataType: 'json'
  }
}

export default apis
