const apis = {
  // cmscoursevideo: {method: "GET",url: "cmscoursevideo/ + id",group: "cms",dataType: "form"},
  pageBy: {
    method: 'GET',
    url: 'cmscoursevideo/pageBy',
    group: 'cms',
    dataType: 'form'
  }
}

export default apis
