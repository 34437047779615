const apis = {
  omsproductconversionlog: {
    method: 'POST',
    url: 'omsproductconversionlog',
    group: 'oms',
    dataType: 'form'
  }
}

export default apis
