const apis = {
  listSubjectGroupBySameProjectDoctorId: { // 废弃接口
    method: 'GET',
    url: 'baseMedicalUnit/listSubjectGroupBySameProjectDoctorId',
    group: 'ums',
    dataType: 'form'
  }
}

export default apis
