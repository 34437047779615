const apis = {
  cmsapplicationforinstallation: {
    method: 'POST',
    url: 'cmsapplicationforinstallation',
    group: 'cms',
    dataType: 'json'
  }
}

export default apis
