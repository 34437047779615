const apis = {
  getVersionCheck: {
    method: 'GET',
    url: 'cmsversioncheck/getVersionCheck',
    group: 'cms',
    dataType: 'form'
  }
}

export default apis
