import project from '@/projectConfig.js'
import user from './user'// 用户相关业务
import wechat from './wechat'// 微信相关业务
import uniLogin from './uniLogin'// uniapp一键登录
import ble from './ble'// uniapp蓝牙ble基础类
import tools from './tools'// 工具集
let global = { user, wechat, uniLogin, ble, tools }

if (!project.projectType.app) global.ble = {}
if (project.framework !== 'uni') global.uniLogin = {}

export default { global }
