const apis = {
  cmstrainuserresume: {
    method: 'PUT',
    url: 'cmstrainuserresume',
    group: 'cms',
    dataType: 'json'
  }
}

export default apis
