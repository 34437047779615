const apis = {
  page: {
    method: 'GET',
    url: 'cmsprojectapplymain/page',
    group: 'cms',
    dataType: 'form'
  }
}

export default apis
