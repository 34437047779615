
import umsmedicalcases from './umsmedicalcases'
import basemedicalunit from './basemedicalunit'
import basesubjectunit from './basesubjectunit'

export default {
  umsmedicalcases,
  basemedicalunit,
  basesubjectunit
}
