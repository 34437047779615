const apis = {
  getCmsProjectApplyContentItemById: {
    method: 'GET',
    url: 'cmsprojectapplycontentitem/getCmsProjectApplyContentItemById',
    group: 'cms',
    dataType: 'form'
  }
}

export default apis
