const apis = {
  getByWxmp: {
    method: 'GET',
    url: 'umsmemberstudio/getByWxmp',
    group: 'ums',
    dataType: 'json'
  }
}

export default apis
