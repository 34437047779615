import { createRouter, createWebHashHistory } from 'vue-router'
import index from '../views/index.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    children: [
      { path: '', redirect: '/login' }
      // { path: '/login', component: () => import('@/views/userCenter/login') }
    ]
  },
  //   {
  //     path: "/about",
  //     name: "About",
  //     // route level code-splitting
  //     // this generates a separate chunk (about.[hash].js) for this route
  //     // which is lazy-loaded when the route is visited.
  //     component: () =>
  //       import(/* webpackChunkName: "about" */ "../views/About.vue")
  //   },
  {
    path: '/userCenter',
    component: () => import('@/views/userCenter'),
    children: [
      { path: '', redirect: 'login' }
      // { path: '/login', component: () => import('@/views/userCenter/login') }
    ]
  },
  {
    path: '/monitor',
    component: () => import('@/views/monitor/index'),
    props: { default: true },
    children: [
      { path: '', redirect: '/monitor/equipment' },
      {
        path: 'equipment',
        component: () => import('@/views/monitor/equipment')
      },
      {
        path: 'state',
        component: () => import('@/views/monitor/state')
      },
      {
        path: 'trends',
        component: () => import('@/views/monitor/trends')
      },
      {
        path: 'class',
        component: () => import('@/views/monitor/class')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/userCenter/login')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
