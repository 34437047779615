import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Element from './plugins/element'
import * as echarts from 'echarts'
import libs from '@/libs'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(Element)
  .mount('#app')

app.echarts = echarts
app.libs = { ...libs }
window.X = { libs }
