const apis = {
  getQRCodeFromUserId: {
    method: 'GET',
    url: 'wyjkqrcode/getQRCodeFromUserId',
    group: 'ums',
    dataType: 'form'
  }
}

export default apis
