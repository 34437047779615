const apis = {
  getnotice: {
    method: 'GET',
    url: 'oanotification/getnotice',
    group: 'core',
    dataType: 'form'
  }
}

export default apis
