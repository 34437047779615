const apis = {
  queryTrainUserCert: {
    method: 'GET',
    url: 'trainUserCert/queryTrainUserCert',
    group: 'cms',
    dataType: 'json'
  }
}

export default apis
